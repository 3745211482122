// JS depedency
var GoogleMapsLoader = require('google-maps');

// Initialisation
GoogleMapsLoader.KEY = 'AIzaSyBEVAlGr13bG5hcuAWcm-qkKEnBRw9VmtE';

if ($('#gmap-simple').length || $('#gmap-cluster').length) {
    GoogleMapsLoader.load(functions);
}

function functions(google) {

    gmap_simple = function () {

        function gmap_simple_init() {
            var map;
            var mapZoom = 14;
            var myLatLng = {
                lat: $('#gmap-simple').data('lat'),
                lng: $('#gmap-simple').data('lng')
            };

            map = new google.maps.Map(document.getElementById("gmap-simple"), {
                mapTypeId: 'roadmap',
                scrollwheel: false,
                panControl: false,
                zoomControl: true,
                zoom: mapZoom,
                center: myLatLng,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false
            });

            var marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                title: 'Hello World!',
            });
        }

        gmap_simple_init();
    }

    gmap_cluster = function () {

        var markerCluster = require('js-marker-clusterer');

        var map = new google.maps.Map(document.getElementById('gmap-cluster'), {
            zoom: $('#gmap-cluster').data('zoom'),
            center: {
                lat: $('#gmap-cluster').data('lat'),
                lng: $('#gmap-cluster').data('lng')
            }
        });

        var infoWin = new google.maps.InfoWindow();
        var markers = locations.map(function (location, i) {
            var marker = new google.maps.Marker({
                position: location
            });
            google.maps.event.addListener(marker, 'click', function (evt) {
                infoWin.setContent(location.info);
                infoWin.open(map, marker);
            });
            return marker;
        });

        var markerCluster = new MarkerClusterer(map, markers, {
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        });
    }

    if ($('#gmap-simple').length) {
        gmap_simple();
    }
    if ($('#gmap-cluster').length) {
        gmap_cluster();
    }
}
